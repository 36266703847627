import { Ability } from '@casl/ability';
import ToastCustom from '@src/helper/ToastCustom';
import { initialAbility } from './initialAbility';

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
// const existingAbility = userData ? userData.ability : null;
const existingAbility = () => {
  let ability = [];

  const cookieService = new Cookies();
  AuthService.post('/auth/login', {
    email: process.env.REACT_APP_EMAIL,
    password: process.env.REACT_APP_MDP,
  })
    .then(response => {
      const jwt = response.data.access_token;
      cookieService.set('jwt', jwt);
      localStorage.setItem('accessToken', jwt);
      VendeurService.get('/users/current-user')
        .then(response => {
          const userData = response.data.data;
          localStorage.setItem('userData', JSON.stringify(response.data.data));
          if (userData !== null && userData.roles.length > 0) {
            userData.roles.forEach(role => {
              // loop through each role (Administrateur / Commercial ...) and add it to the ability
              role.permissions.forEach(permission => {
                // loop through each permission (read, write, delete ...) and add it to the ability
                let i = permission.name.indexOf(' ');
                let action = permission.name.substring(0, i);
                let resource = permission.name.substring(i + 1);
                ability.push({ action, subject: resource });
              });
            });
            ability.push({ action: 'view', subject: 'app' });
            return ability;
          }
        })
        .catch(error => {
          console.error(error);
          ToastCustom.error('serverError');
        });
    })
    .catch(error => {
      console.error(error);
      ToastCustom.error('serverError');
    });
};

export default new Ability(existingAbility || initialAbility);
