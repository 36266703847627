import ToastCustom from '@src/helper/ToastCustom';
import axios from 'axios';
import i18n from 'i18next';
import backend from 'i18next-http-backend';
import { initReactI18next, useTranslation } from 'react-i18next';

const loadResources = async locale => {
  let lang = '';
  switch (locale) {
    case 'en':
      lang = 'en_EN';
      break;
    case 'fr':
      lang = 'fr_FR';
      break;
    default:
      lang = 'fr_FR';
  }
  return await axios
    .get(
      `${process.env.REACT_APP_TRANSLATION_BASE_URL}/translations?appUuid=${process.env.REACT_APP_APP_UUID}&languageTag=${lang}`,
      { timeout: 5000 }
    )
    .then(response => {
      return response.data.data[0].Translations;
    })
    .catch(error => {
      ToastCustom.error('serverError');
      console.error(error);
    });
};

const backendOptions = {
  loadPath: '{{lng}}|{{ns}}',
  request: (options, url, payload, callback) => {
    try {
      const [lng] = url.split('|');
      loadResources(lng).then(response => {
        callback(null, {
          data: response,
          status: 200,
        });
      });
    } catch (e) {
      console.error(e);
      callback(null, {
        status: 500,
      });
    }
  },
};

i18n
  .use(initReactI18next)
  .use(backend)
  .init(
    {
      backend: backendOptions,
      fallbackLng: 'fr',
      ns: ['fr', 'en', 'de'],
      defaultNS: 'fr',
      defaultLanguage: 'fr',
      debug: false,
      load: 'languageOnly',
      keySeparator: false,
    },
    (err, t) => {
      if (err) return console.error('something went wrong loading', err);
    }
  );

export default i18n;
