import Swal from 'sweetalert2';
import i18next from 'i18next';

const SwalCustom = {
  withUpload: function (
    preConfirm = () => {},
    onConfirm = () => {},
    title = 'uploadExcelFile',
    confirmButtonText = 'import',
    cancelButtonText = 'refuse',
    acceptExtensions = ['.xlsx']
  ) {
    Swal.fire({
      title: i18next.t(title),
      inputAttributes: {
        excludeAcceptAllOption: true,
        accept: acceptExtensions,
      },
      input: 'file',
      showCancelButton: true,
      animation: 'slide-from-top',
      padding: '1.5rem 1.5rem',
      confirmButtonText: i18next.t(confirmButtonText).toUpperCase(),
      cancelButtonText: i18next.t(cancelButtonText).toUpperCase(),
      showLoaderOnConfirm: true,
      inputPlaceholder: i18next.t('enterLabel'),
      backdrop: true,
      onOpen: () => Swal.getConfirmButton().focus(),
      customClass: {
        confirmButton: 'btn btn-flat-danger text-danger text-uppercase',
        cancelButton: 'mx-1 btn btn-flat-secondary text-dark text-uppercase',
        title: 'p-0 pb-2 pt-1',
        input: 'swall-input focus:border-primary',
        actions: 'mt-2 flex-row-reverse',
        popup: 'd-flex flex-column align-items-center justify-content-center',
      },

      preConfirm: async file => {
        //Vérifier que le fichier sélectionnés à une extension autorisée
        const splitArray = file.name.split('.');
        if (
          !acceptExtensions.includes(`.${splitArray[splitArray.length - 1]}`)
        ) {
          Swal.showValidationMessage(
            `${i18next.t('badFileExtension')} : ${acceptExtensions}`
          );
        } else {
          await preConfirm(file);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(r => {
      if (r.isConfirmed) {
        onConfirm();
      }
    });
  },
  withInput: function (
    onConfirm = () => {},
    {
      preConfirm = () => {},
      title = i18next.t('confirmInput'),
      text = i18next.t('messageConfirmInput'),
      inputPlaceholder = i18next.t('enterLabel'),
      confirmButtonText = i18next.t('continue').toUpperCase(),
      cancelButtonText = i18next.t('refuse'),
    } = {}
  ) {
    Swal.fire({
      title: i18next.t(title),
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
        borderColor: 'red',
      },
      width: '40rem',
      padding: '2rem',
      reverseButtons: true,
      inputOptions: {},
      showCancelButton: true,
      confirmButtonText: i18next.t(confirmButtonText).toUpperCase(),
      cancelButtonText: i18next.t(cancelButtonText).toUpperCase(),
      showLoaderOnConfirm: true,
      inputPlaceholder: inputPlaceholder,
      backdrop: true,
      focusCancel: true,
      customClass: {
        popup: 'swal2-popup-custom p-2',
        title: 'pb-2 m-2',
        actions: 'mt-3',
        input: 'form-control max-w-90' ,
        confirmButton: 'btn btn-flat text-danger',
        cancelButton: 'mx-1 btn btn-flat-secondary text-dark',
      },
      inputValidator: value => {
        if (!value) {
          return i18next.t('requiredField');
        }
      },
      preConfirm: inputValue => {
        preConfirm(inputValue);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(r => {
      if (r.isConfirmed && r.value !== '') {
        onConfirm(r.value);
      }
    });
  },

  delete: function (
    onConfirm = () => {},
    {
      title = 'confirmDelete',
      text = 'messageConfirmDelete',
      showCancelButton = true,
      confirmButtonText = 'delete',
      cancelButtonText = 'refuse',
      icon = 'warning',
      iconColor = 'red',
    } = {}
  ) {
    Swal.fire({
      title: i18next.t(title),
      text: i18next.t(text),
      width: '40rem',
      padding: '2rem',
      icon: icon,
      iconColor: iconColor,
      customClass: {
        actions: 'mt-3 flex-row-reverse',
        confirmButton: 'btn btn-flat-danger text-danger text-uppercase',
        cancelButton: 'mx-1 btn btn-flat-secondary text-dark text-uppercase',
      },
      showCancelButton: showCancelButton,
      cancelButtonText: i18next.t(cancelButtonText),
      confirmButtonText: i18next.t(confirmButtonText),
    }).then(r => {
      if (r.isConfirmed) {
        onConfirm();
      }
    });
  },
  lost: (
    onConfirm = () => {},
    {
      title = 'confirmLost',
      text = 'messageConfirmLost',
      showCancelButton = true,
      confirmButtonText = 'continue',
      cancelButtonText = 'refuse',
      icon = 'warning',
    } = {}
  ) => {
    Swal.fire({
      title: i18next.t(title),
      text: i18next.t(text),
      width: '40rem',
      padding: '2rem',
      icon: icon,
      customClass: {
        actions: 'mt-3',
        confirmButton: 'btn btn-flat-danger text-danger',
        cancelButton: 'mx-1 btn btn-flat-secondary text-dark',
      },
      reverseButtons: true,
      showCancelButton: showCancelButton,
      confirmButtonText: i18next.t(confirmButtonText),
      cancelButtonText: i18next.t(cancelButtonText),
    }).then(r => {
      if (r.isConfirmed) {
        onConfirm();
      }
    });
  },
  success: function (title = 'success', text = '') {
    Swal.fire({
      title: i18next.t(title),
      text: i18next.t(text),
      type: 'success',
      position: 'center',
      showConfirmButton: false,
      timer: 1500,
      buttonsStyling: false,
      customClass: {
        popup: 'swal2-popup-custom',
      },
    });
  },
  error: function (title = 'error', text = '', btnText = 'close') {
    Swal.fire({
      title: i18next.t(title),
      text: i18next.t(text),
      type: 'error',
      position: 'center',
      icon: 'error',
      buttonsStyling: false,
      confirmButtonText: i18next.t(btnText),
      customClass: {
        popup: 'swal2-popup-custom',
        confirmButton: 'btn btn-flat-danger text-danger',
      },
    });
  },
  info: function (
    onConfirm = () => {},
    {
      title = i18next.t('messageConfirmUpdate'),
      text = undefined,
      showCancelButton = true,
      confirmButtonText = 'continue',
      cancelButtonText = 'refuse',
      icon = 'info',
      iconColor = 'orange',
    } = {}
  ) {
    Swal.fire({
      title: i18next.t(title),
      text: i18next.t(text),
      width: '40rem',
      padding: '2rem',
      icon: icon,
      iconColor: iconColor,
      customClass: {
        actions: 'mt-3 flex-row-reverse',
        confirmButton: 'btn btn-flat-danger text-danger text-uppercase',
        cancelButton: 'mx-1 btn btn-flat-secondary text-dark text-uppercase',
      },
      showCancelButton: showCancelButton,
      cancelButtonText: i18next.t(cancelButtonText),
      confirmButtonText: i18next.t(confirmButtonText),
    }).then(r => {
      if (r.isConfirmed) {
        onConfirm();
      }
    });
  },
};

export default SwalCustom;
