import authProvider from '@src/data/http_services/auth_provider_service';
import VendeurService from '@src/data/http_services/vendeur_service';
import ability from '@configs/acl/ability';
import { updateAbility } from '@configs/acl/updateAbility';

import Cookies from 'universal-cookie';

import jwtDecode from 'jwt-decode';

import moment from 'moment';

export default function useAuth() {
  const getJWT = () => {
    const cookies = new Cookies();
    const jwt = cookies.get('jwt');
    return jwt;
  };

  const checkJWT = jwt => {
    const expirationDate = moment.unix(jwtDecode(jwt).exp);
    const diffInSecond = expirationDate.diff(moment(), 'second');
    // if JWT expire in less than 30 seconds
    if (diffInSecond <= 100 || diffInSecond < 0) {
      return 'refreshable';
    }
    return 'valid';
  };

  const cookieService = new Cookies();

  const initAuth = async () => {
    let jwt = '';
    if (process.env.REACT_APP_ENV_TYPE === 'LOCAL') {
      const response = await authProvider.post('/auth/login', {
        email: process.env.REACT_APP_EMAIL,
        password: process.env.REACT_APP_MDP,
      });
      jwt = response.data.access_token;
      cookieService.set('jwt', jwt.replace('"', ''));
    } else {
      const cookies = new Cookies();
      jwt = cookies.get('jwt', { domain: '.xefi-apps.fr' });
    }

    if (jwt) {
      const res = await VendeurService.get('/users/current-user');
      const user = res.data.data;
      localStorage.setItem('userData', JSON.stringify(user));
      updateAbility(ability, user);
      return res.data.data;
    } else {
      cookieService.remove('jwt', { domain: '.xefi-apps.fr' });
      cookieService.remove('jwt');
      window.location.href = process.env.GATSBY_APP_DAILY_APPS_URL;
    }
  };

  return {
    getJWT,
    checkJWT,
    initAuth,
    ability,
  };
}
