import Cookies from 'universal-cookie';

import AuthService from './auth_provider_service';
import useAuth from '@src/utility/hooks/useAuth';

import * as Sentry from '@sentry/react';

const { checkJWT } = useAuth();

export async function requestInterceptor(config) {
  if (!config.url.includes('login')) {
    const cookieService = new Cookies();
    const jwt = cookieService.get('jwt');
    try {
      if (jwt) {
        const jwtStatus = checkJWT(jwt);
        if (jwtStatus === 'refreshable') {
          const response = await AuthService.post(
            '/auth/refresh',
            {},
            {
              headers: {
                Authorization: `bearer ${jwt}`,
              },
            }
          );
          cookieService.set('jwt', response.data.access_token);
          config.headers.Authorization = response.data.access_token;
        }
        if (jwtStatus === 'valid') {
          config.headers.Authorization = jwt;
        }
      } else {
        cookieService.remove('jwt');
        cookieService.remove('jwt', { domain: '.xefi-apps.fr' });
        window.location.href = `${process.env.REACT_APP_PORTAIL_URL}/auth`;
      }
    } catch (err) {
      console.error(err);
      cookieService.remove('jwt');
      window.location.href = `${process.env.REACT_APP_PORTAIL_URL}/auth`;
      cookieService.remove('jwt', { domain: '.xefi-apps.fr' });
    }
  }
  return config;
}

export function responseInterceptor(error) {
  Sentry.captureException(error);
  Sentry.captureMessage(error?.response?.data?.message);
  if (error?.response?.status === 401) {
    const cookies = new Cookies();
    cookies.remove('jwt');
    cookies.remove('jwt', { domain: '.xefi-apps.fr' });
    window.location.href = `${process.env.REACT_APP_PORTAIL_URL}/auth`;
  }
  if (error?.message === 'canceled') {
    return; // ** Ignore canceled requests
  }
  if (error?.response?.config?.url === '/events/leaves') {
    return; // ignore conges requests
  }
  console.error(error);

  return Promise.reject(error);
}
