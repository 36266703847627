import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import vendeurService from '../data/http_services/vendeur_service';
import SwalCustom from '../helper/SwalCustom';
import ToastCustom from '../helper/ToastCustom';


export const fetchNotification = createAsyncThunk(
  'notifications/fetchNotification',
  async () => {
    const response = await vendeurService
      .get('/notifications')
      .catch(function (e) {
        const customError = {
          name: e.response.data.message,
          message: e.response.data.details,
        };
        throw customError;
      });
    return response.data.data.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
  }
);

export const readNotification = createAsyncThunk(
  'notifications/readNotification',
  async data => {
    const response = await vendeurService
      .put(`/notifications/${data}/read`)
      .catch(function (e) {
        const customError = {
          name: e.response.data.message,
          message: e.response.data.details,
        };
        throw customError;
      });
    return response.data.id;
  }
);

export const readAllNotification = createAsyncThunk(
  'notifications/readAllNotification',
  async data => {
    const response = await vendeurService
      .put(`/notifications/mark-all-as-read`)
      .catch(function (e) {
        const customError = {
          name: e.response.data.message,
          message: e.response.data.details,
        };
        throw customError;
      });
    return response.data;
  }
);

export const deleteNotification = createAsyncThunk(
  'notifications/deleteNotification',
  async data => {
    const response = await vendeurService
      .delete(`/notifications/${data}`)
      .catch(function (e) {
        const customError = {
          name: e.response.data.message,
          message: e.response.data.details,
        };
        throw customError;
      });
    return response.data.id;
  }
);

export const customNotificationAction = createAsyncThunk(
  'notifications/customNotificationAction',
  async (data, { dispatch }) => {
    try {
      const response = await vendeurService(data.route, {
        method: data.action,
        data: data.body,
      });
      dispatch(fetchNotification());
      return response.data.data;
    } catch (err) {
    }
  }
);

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    loading: false,
    list: [],
  },
  reducers: {},
  extraReducers: {
    [fetchNotification.pending]: state => {
      state.loading = true;
    },
    [fetchNotification.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload;
    },
    [fetchNotification.rejected]: (state, action) => {
      state.loading = false;
    },
    [readNotification.pending]: state => {
      state.loading = true;
    },
    [readNotification.fulfilled]: (state, action) => {
      const notification = state.list.find(t => t.id === action.payload);
      notification.read_at = Date.now();
      state.loading = false;
      ToastCustom.success('notificationReadWithSuccess');
    },
    [readNotification.rejected]: (state, action) => {
      SwalCustom.error('error', action.error.name);
      state.loading = false;
    },
    [readAllNotification.pending]: state => {
      state.loading = true;
    },
    [readAllNotification.fulfilled]: (state, action) => {
      function read(notification) {
        notification.read_at = Date.now();
      }
      state.list.forEach(t => {
        if (t.read_at === null) {
          read(t);
        }
      });
      state.loading = false;
      ToastCustom.success('allNotificationReadWithSuccess');
    },
    [readAllNotification.rejected]: (state, action) => {
      SwalCustom.error('error', action.error.name);
      state.loading = false;
    },
    [deleteNotification.pending]: state => {
      state.loading = true;
    },
    [deleteNotification.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = state.list.filter(t => t.id !== action.payload);
      ToastCustom.success('deleteSuccess');
      return state;
    },
    [deleteNotification.rejected]: (state, action) => {
      SwalCustom.error('error', action);
      state.loading = false;
    },
    [customNotificationAction.pending]: state => {
      state.loading = true;
    },
    [customNotificationAction.fulfilled]: (state, action) => {
      state.loading = false;
      ToastCustom.success(action?.payload?.message);
    },
    [customNotificationAction.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default notificationSlice.reducer;
