import { AbilityBuilder } from '@casl/ability';

export function updateAbility(ability, userData) {
  if (userData !== null && userData.roles.length > 0) {
    const { can, rules } = new AbilityBuilder();
    userData.roles.forEach(role => {
      // loop through each role (Administrateur / Commercial ...) and add it to the ability
      role.permissions.forEach(permission => {
        // loop through each permission (read, write, delete ...) and add it to the ability
        let i = permission.name.indexOf(' ');
        let action = permission.name.substring(0, i);
        let resource = permission.name.substring(i + 1);
        can(action, resource);
      });
    });
    ability.update(rules);
  }
}
