import i18next from 'i18next';
import toast from 'react-hot-toast';

const ToastCustom = {
  success: function (title = 'updateSuccess') {
    toast.success(i18next.t(title), {
      position: 'bottom-center',
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
      },
    });
  },
  error: function (title = 'updateError') {
    toast.error(i18next.t(title), {
      position: 'bottom-center',
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
      },
      className: 'text-center',
    });
  },
};

export default ToastCustom;
