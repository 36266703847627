// ** Reducers Imports
import auth from './authentication';
import layout from './layout';
import navbar from './navbar';
import notifications from './notification';

const rootReducer = {
  auth,
  layout,
  navbar,
  notifications,
};

export default rootReducer;
