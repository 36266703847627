import axios from 'axios';
import {
  requestInterceptor,
  responseInterceptor,
} from './interceptor_services';

const authProviderService = axios.create({
  baseURL: process.env.REACT_APP_PORTAIL_API,
  timeout: 90000,
  headers: {
    fromApp: process.env.REACT_APP_AUTH_UUID,
    'Content-Type': 'application/json; charset=utf-8',
  },
});

//Intercepte les requêtes
// authProviderService.interceptors.request.use(
//   requestInterceptor,
//   function (error) {
//     return Promise.reject(error);
//   }
// );

// //Intercepte les réponses aux requêtes
// authProviderService.interceptors.response.use(
//   response => response,
//   error => responseInterceptor(error)
// );

export default authProviderService;
