import axios from "axios";
import {
  requestInterceptor,
  responseInterceptor,
} from "./interceptor_services";

const vendeurService = axios.create({
  baseURL: process.env.REACT_APP_VENDEUR_BASE_URL,
  timeout: 90000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
    "Access-Control-Allow-Origin": "*",
  },
});

//Intercepte les requêtes
vendeurService.interceptors.request.use(requestInterceptor);

//Intercepte les réponses aux requêtes
vendeurService.interceptors.response.use(
  (response) => response,
  (error) => responseInterceptor(error)
);

export default vendeurService;
